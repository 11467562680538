import React, { forwardRef } from 'react'
import Layout from '../components/layout/layout'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { BaseLink } from '../components/elements/link'
import {
  patronPackageUrl,
  SpacedHeading,
  sponsorPackageUrl,
} from '../components/page/package/elements'
import { Slider } from '../components/functionality/slider/slider'
import { StaticImage } from 'gatsby-plugin-image'
import { PackageSection } from '../components/page/package/packageSection'

const HeaderImage = () => {
  const commonProps = {
    alt: 'Karma Virtual Book Tour',
    layout: 'fullWidth',
  }

  return (
    <>
      <Box display={['block', 'none']}>
        <StaticImage
          src={'../images/package/headerMobile.jpg'}
          {...commonProps}
        />
      </Box>
      <Box display={{ base: 'none', sm: 'block', md: 'none' }}>
        <StaticImage
          src={'../images/package/headerTablet.jpg'}
          {...commonProps}
        />
      </Box>
      <Box display={{ base: 'none', md: 'block', lg: 'none' }}>
        <StaticImage
          src={'../images/package/headerLaptop.jpg'}
          {...commonProps}
        />
      </Box>
      <Box display={{ base: 'none', lg: 'block' }}>
        <StaticImage
          src={'../images/package/headerDesktop.jpg'}
          {...commonProps}
        />
      </Box>
    </>
  )
}

const Header = () => {
  return (
    <Box position={'relative'}>
      <HeaderImage />
      <Flex
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        flexDirection={'column'}
        fontSize={{ base: '6vw', sm: '3vw', md: '2.5vw', lg: '2vw' }}
      >
        <Heading
          as={'h1'}
          color={'white'}
          fontSize={{ base: '2.7em', md: '3em' }}
          textTransform={'uppercase'}
          lineHeight={1}
        >
          Karma
        </Heading>
        <SpacedHeading
          as={'div'}
          color={'gold'}
          fontSize={'1.1em'}
          mb={['.7em', '2.5em', '1.5em', '2em']}
        >
          Virtual Book Tour
        </SpacedHeading>
        <Stack
          mb={{ base: '1.5em', md: '1em' }}
          spacing={{ base: '.7em', sm: '2em' }}
          direction={['column', 'row']}
        >
          {[
            { text: 'Patron Package', url: patronPackageUrl },
            { text: 'Sponsor Package', url: sponsorPackageUrl },
          ].map(({ text, url }) => (
            <Button
              key={url}
              border={'solid 1px white'}
              minWidth={'12em'}
              as={BaseLink}
              href={url}
            >
              {text}
            </Button>
          ))}
        </Stack>
      </Flex>
    </Box>
  )
}

const IntroSection = () => (
  <Container py={8}>
    <Text fontSize={['lg', '2xl']} color={'blue'} textAlign={'center'}>
      Exciting conversations coming up with <strong>Sadhguru</strong>. Each{' '}
      <strong>unique conversation</strong> will delve into the many{' '}
      <strong>intriguing facets of karma.</strong>
    </Text>
  </Container>
)

const Event = forwardRef(({ name, date, image }, ref) => (
  <div ref={ref}>
    <Box width={'280px'} px={4} pb={4}>
      <Image src={image} alt={name} mb={2} />
      <Heading as={'h3'} fontSize={'4xl'} color={'blue'}>
        {name}
      </Heading>
      <Text fontSize={'lg'} color={'blue'} lineHeight={'1.3'}>
        {date}
      </Text>
    </Box>
  </div>
))

Event.displayName = 'Event'

const EventsSection = () => (
  <Box pb={8} pt={4}>
    <Container maxW={'container.sm'} mb={6}>
      <SpacedHeading color={'gold'} textAlign={'center'}>
        Book Tour Events
      </SpacedHeading>
    </Container>
    <Slider justifyContent={'center'} dotColor={'gold'}>
      <Event
        name={'Deepak Chopra'}
        date={'April 29th at 7PM EDT'}
        image={
          'https://innerengineering.sadhguru.org/lib/img/karma-book/live-events/deepakChopraEvent.jpg'
        }
      />
      <Event
        name={'Mark Hyman'}
        date={'May 6th at 8:30PM EDT'}
        image={
          'https://innerengineering.sadhguru.org/lib/img/karma-book/live-events/mark-hyman.jpeg'
        }
      />
      <Event
        name={'Terry Tamminen'}
        date={'May 18th'}
        image={
          'https://innerengineering.sadhguru.org/lib/img/karma-book/live-events/terry-tamminen.jpg'
        }
      />
      <Event
        name={'Philip Goldberg'}
        date={'May 20th at 7:00PM EDT'}
        image={
          'https://innerengineering.sadhguru.org/lib/img/karma-book/live-events/philip-goldberg.jpg'
        }
      />
      <Event
        name={'Kunal Nayyar'}
        date={'May 22nd at 7:00PM EDT'}
        image={
          'https://innerengineering.sadhguru.org/lib/img/karma-book/live-events/kunal-nayyar.jpg'
        }
      />
    </Slider>
  </Box>
)

export default function PackagePage() {
  return (
    <Layout
      meta={{
        title: 'Karma - Virtual Book Tour',
        description:
          'Exciting conversations coming up with Sadhguru. Each unique conversation will delve into the many intriguing facets of karma.',
      }}
      fadeNav={true}
    >
      <Header />
      <Box bgColor={'tan.50'} pb={12}>
        <IntroSection />
        <EventsSection />
        <PackageSection />
      </Box>
    </Layout>
  )
}
